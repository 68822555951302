// Require intersection-observer polyfill before requiring any other modules.
import 'intersection-observer';

//import $ from "cash-dom";
//import SmoothScroll from "smooth-scroll/dist/smooth-scroll.min.js";
import 'lazysizes';
// import a plugin unveilhooks for video lazyload and autoplay when loaded
//import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

//for scroll animations
import sal from 'sal.js'
sal({
    threshold: 0.2
});


//import 'main/scroll_events';
//import 'main/paralax';
//import 'main/script';
//import 'main/cookies';
